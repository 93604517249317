import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available'
  },
  sidebar: {
    campaigns: 'Campaigns',
    audiences: 'Audiences'
  },
  audience: {
    listPage: {
      searchPlaceholder: 'Search by name',
      audience: 'Audiences',
      loading: 'Loading items...',
      estimatedTarget: 'Current estimated target',
      createdOn: 'Created on',
      lastUpdate: 'Updated on',
      campaigns: 'Campaigns',
      actions: 'Actions',
      estimatedTargetInfo: 'This conveys the estimated number of POCs in this audience.',
      lastUpdateInfo: 'This number is updated daily to reflect the estimated target audience.',
      addNew: 'Create new audience',
      openExportedFiles: 'Open Exported files',
      update: 'Update',
      itemsPerPage: 'Items per page: {value}',
      status: 'Status',
      statusProcessing: 'Processing',
      statusNotProcessed: 'Not processed',
      statusProcessedWithErrors: 'Processed with errors',
      statusProcessedSuccess: 'Successfully processed',
      name: 'Name',
      created: {
        audienceCreated: 'Audience created!'
      },
      saved: {
        audienceSaved: 'Audience saved!'
      },
      noAudiences: {
        description: 'Your audiences will be shown here.',
        btnLabel: 'Create new audience'
      },
      errorGetAudiences: {
        description: "We couldn't load the list. Try reloading.",
        btnLabel: 'Reload list'
      },
      errorSearchingAudiences: {
        description: 'No results found. Try changing your search.',
        btnLabel: 'Clear search'
      }
    },
    audiencePage: {
      newAudience: 'New audience',
      leaveModal: {
        title: 'Leave audience creation?',
        body: 'All unsaved changes will be lost.',
        leaveButton: 'Leave',
        cancelButton: 'Cancel'
      },
      vendorModal: {
        title: 'Change vendor?',
        body: 'If you change vendor, any unsaved changes will be lost.'
      },
      countryModal: {
        title: 'Change country?',
        body: 'If you change country, any unsaved changes will be lost.'
      },
      sellerModal: {
        title: 'Change seller?',
        body: 'If you change seller, any unsaved changes will be lost.'
      },
      leaveButton: 'Yes, change',
      cancelButton: 'No, go back'
    },
    deleteAudienceModal: {
      title: 'Delete audience?',
      buttonDelete: 'Yes, delete audience',
      buttonCancel: 'No, go back',
      default: 'This action cannot be undone.',
      linked:
        'Unreleased campaigns linked to this audience will appear in an Error state, and you will need to select new audiences for them.'
    },
    exportedFilesPage: {
      exportedFilesTitle: 'Exported files'
    }
  },
  actions: {
    edit: 'Edit',
    delete: 'Delete'
  },
  sidebarCMS: {
    Home: 'Home',
    BeesCustomer: 'BEES Customer',
    BeesEngage: 'BEES Engage',
    MiNegocio: 'Mi Negocio',
    AudienceEditor: 'Audience Editor'
  },
  sidebarOffer: {
    OfferList: 'Offer list',
    AudienceBuilder: 'Audience builder'
  },
  useSidebarExperimentationPlatform: {
    Home: 'Experimentation Platform',
    AudienceEditor: 'Audience Editor'
  },
  useSidebarForce: {
    Home: 'Task management'
  }
};

export default enUS;
