import { useIntl } from 'react-intl';

export const useAudiencePageIntl = () => {
  const { formatMessage } = useIntl();
  const newAudience = formatMessage({ id: 'audience.audiencePage.newAudience' });
  const title = formatMessage({ id: 'audience.audiencePage.leaveModal.title' });
  const body = formatMessage({ id: 'audience.audiencePage.leaveModal.body' });
  const leaveButton = formatMessage({ id: 'audience.audiencePage.leaveModal.leaveButton' });
  const cancelButton = formatMessage({ id: 'audience.audiencePage.leaveModal.cancelButton' });
  const audienceSaved = formatMessage({ id: 'audience.listPage.saved.audienceSaved' });
  const audienceCreated = formatMessage({ id: 'audience.listPage.created.audienceCreated' });
  const titleVendor = formatMessage({ id: 'audience.audiencePage.vendorModal.title' });
  const bodyVendor = formatMessage({ id: 'audience.audiencePage.vendorModal.body' });
  const leaveButtonModal = formatMessage({ id: 'audience.audiencePage.leaveButton' });
  const cancelButtonModal = formatMessage({
    id: 'audience.audiencePage.cancelButton'
  });
  const titleCountry = formatMessage({ id: 'audience.audiencePage.countryModal.title' });
  const bodyCountry = formatMessage({ id: 'audience.audiencePage.countryModal.body' });
  const titleSeller = formatMessage({ id: 'audience.audiencePage.sellerModal.title' });
  const bodySeller = formatMessage({ id: 'audience.audiencePage.sellerModal.body' });

  const titleExportingFileModal = formatMessage({
    id: 'audience.audiencePage.exportingFileModal.titleExportingFileModal'
  });
  const bodyExportingFileModal = formatMessage({
    id: 'audience.audiencePage.exportingFileModal.bodyExportingFileModal'
  });
  const leaveButtonExportingFileModal = formatMessage({
    id: 'audience.audiencePage.exportingFileModal.leaveButtonExportingFileModal'
  });
  const cancelButtonExportingFileModal = formatMessage({
    id: 'audience.audiencePage.exportingFileModal.cancelButtonExportingFileModal'
  });

  return {
    leaveModal: {
      title,
      body,
      leaveButton,
      cancelButton
    },
    vendorModal: {
      titleVendor,
      bodyVendor,
      leaveButtonModal,
      cancelButtonModal
    },
    countryModal: {
      titleCountry,
      bodyCountry,
      leaveButtonModal,
      cancelButtonModal
    },
    sellerModal: {
      titleSeller,
      bodySeller,
      leaveButtonModal,
      cancelButtonModal
    },
    newAudience,
    audienceCreated,
    audienceSaved,
    exportingFileModal: {
      titleExportingFileModal,
      bodyExportingFileModal,
      leaveButtonExportingFileModal,
      cancelButtonExportingFileModal
    }
  };
};
