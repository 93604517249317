import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  },
  sidebar: {
    campaigns: 'Campanhas',
    audiences: 'Públicos'
  },
  audience: {
    listPage: {
      searchPlaceholder: 'Pesquisar por nome',
      audience: 'Públicos',
      loading: 'Carregando itens...',
      estimatedTarget: 'Meta atual estimada',
      createdOn: 'Criada em',
      lastUpdate: 'Atualizada em',
      campaigns: 'Campanhas',
      actions: 'Ações',
      estimatedTargetInfo: 'Refere-se o número estimado de PDVs nesse Público.',
      lastUpdateInfo: 'This number is updated daily to reflect the estimated target audience.',
      addNew: 'Criar novo público',
      openExportedFiles: 'Abrir arquivos exportados',
      update: 'Atualizar',
      itemsPerPage: 'Itens por página: {value}',
      name: 'Nome',
      status: 'Status',
      statusProcessing: 'Processando',
      statusNotProcessed: 'Não processado',
      statusProcessedWithErrors: 'Processado com erros',
      statusProcessedSuccess: 'Processado com sucesso',
      created: {
        audienceCreated: 'Público criado!'
      },
      saved: {
        audienceSaved: 'Público salvo!'
      },
      noAudiences: {
        description: 'Seus públicos serão mostrados aqui.',
        btnLabel: 'Criar novo público'
      },
      errorGetAudiences: {
        description: 'Não foi possível carregar a lista. Tente recarregar a página.',
        btnLabel: 'Recarregar lista'
      },
      errorSearchingAudiences: {
        description: 'Nenhum resultado encontrado. Tente alterar sua pesquisa.',
        btnLabel: 'Limpar pesquisa'
      }
    },
    audiencePage: {
      newAudience: 'Novo público',
      leaveModal: {
        title: 'Sair da criação de público?',
        body: 'Todas as alterações não salvas serão perdidas',
        leaveButton: 'Sair',
        cancelButton: 'Cancelar'
      },
      vendorModal: {
        title: 'Alterar fornecedor?',
        body: 'Se você alterar o fornecedor, as alterações não salvas serão perdidas.'
      },
      countryModal: {
        title: 'Alterar país?',
        body: 'Se você alterar o país, as alterações não salvas serão perdidas.'
      },
      sellerModal: {
        title: 'Alterar vendedor?',
        body: 'Se você alterar o vendedor, as alterações não salvas serão perdidas.'
      },
      leaveButton: 'Sim, alterar',
      cancelButton: 'Não, voltar'
    },
    deleteAudienceModal: {
      title: 'Delete audience?',
      buttonDelete: 'Yes, delete audience',
      buttonCancel: 'No, go back',
      default: 'This action cannot be undone.',
      linked:
        'Unreleased campaigns linked to this audience will appear in an Error state, and you will need to select new audiences for them.'
    },
    exportedFilesPage: {
      title: 'Arquivos exportados',
      table: {
        toolbar: {
          search: 'Pesquisar por nome',
          refresh: 'Atualizar'
        },
        header: {
          audienceName: 'Nome do público',
          type: 'Tipo',
          fileName: 'Nome do arquivo',
          status: 'Status',
          estimatedTarget: 'Meta estimada',
          actions: 'Ações'
        },
        columns: {
          types: {
            invalidPocs: 'PDVs inválidos'
          },
          status: {
            processing: 'Processando',
            done: 'Processado',
            failed: 'Falha'
          }
        }
      }
    }
  },
  actions: {
    edit: 'Editar',
    delete: 'Excluir'
  },
  sidebarCMS: {
    Home: 'Home',
    BeesCustomer: 'BEES Customer',
    BeesEngage: 'BEES Engage',
    MiNegocio: 'Mi Negocio',
    AudienceEditor: 'Audience Editor'
  },
  sidebarOffer: {
    OfferList: 'Lista de ofertas',
    AudienceBuilder: 'Criador de audiências'
  },
  useSidebarForce: {
    Home: 'Gerenciamento de tarefas'
  }
};

export default ptBR;
